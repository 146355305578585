* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 16px; }
img {
    max-width: 700px; }
.kleinereBilder {
    max-width: 400px; }
.miniBilder {
    max-width: 200px; }

.editor {
    background: #000;
    border-radius: 8px;
    padding: 1rem 2rem;
    margin: 2rem 0;
    color: white; }

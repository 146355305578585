#menu {
    text-align: center;
    .submenu {
        height: 0;
        transition: all 150ms ease;
        overflow: hidden;
        background: #333;
        .submenuItem {
            padding: 5px;
            text-transform: none;
            letter-spacing: 0;
            transition: all 150ms ease;
            cursor: pointer;
            &:hover {
                background: #444; }
            &.active {
                background: $main; } } }
    .menuItem {
        min-width: 150px;
        font-size: 12px;
        a {
            font-size: 12px;
            transition: all 150ms ease;
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.05rem;
            padding: 10px;
            display: block;
            border-bottom: 1px solid #444;
            cursor: pointer;
            &:hover {
                background: #333; }
            &.active {
                background: $main;
                &~.submenu {
                    height: 100%; } } } }

    .menupunkte {
        // background: #bcbcbc
        // width: 300px
        text-align: center;
        color: white;
        padding: 10px 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid #444;
        &:nth-child(even) {
 }            // background: #7D7D7D
        p {
            letter-spacing: 0.05rem;
            text-transform: uppercase; } }

    li {
        margin: 7px auto;
        list-style: none;
        color: white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            transition: all 300ms ease;
            color: 	#4f4f4f; } }
    .zugaengemenu {
        margin: 0!important;
        &:hover {
            color: 	white; } }

    hr {
        margin: 10px; }

    img {
        cursor: pointer;
        margin: 30px;
        align-items: center; }

    .unterpunkte {
        height: 0;
        overflow: hidden;
        transition: all 300ms ease; } }








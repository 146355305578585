
// @import url('https://fonts.googleapis.com/css?family=Montserrat')

* {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	line-height: 1.5; }


h2 {
	font-size: 1.5em;
	margin-bottom: 15px;
	letter-spacing: 0.01rem; }

h1 {
	font-size: 2em;
	letter-spacing: 0.01rem; }
a {
	color: #ff6600;
	&:hover {
		color: black; } }

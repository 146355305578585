.menuWrapper.backend {
	padding: 0 20px;
	text-align: left;
	color: white;
	font-size: 12px;
	h3 {
		font-size: 12px;
		color: white!important;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: .05rem; }
	hr {
		border: none;
		background: #333;
		height: 1px;
		width: 100%;
		margin: 10px 0!important;
		padding: 0; }
	strong {
		font-size: 12px;
		display: block;
		color: white;
		margin-bottom: 10px;
		text-align: left; }
	input[type="text"], textarea, button {
		all: unset;
		box-sizing: border-box;
		color: #333; }
	input[type="text"] {
		background: #fcfcfc;
		padding: 5px 10px;
		font-size: 12px;
		display: block;
		width: 100%;
		max-width: 100%;
		margin-bottom: 5px; }
	label {
		font-size: 12px;
		margin-left: 5px; }
	input[type="checkbox"] {
		margin-top: 10px; }
	textarea {
		background: #fcfcfc;
		display: block;
		width: 100%;
		margin: 0;
		padding: 5px 10px;
		height: 70px;
		resize: vertical; }
	button {
		display: block;
		background: $main;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin-top: 20px;
		position: relative;
		box-shadow: 1px 1px 15px rgba(black, 0.3), 0 0 5px rgba(black, 0.5);
		transition: all 300ms ease;
		cursor: pointer;
		float: right;
		&:hover {
			background: lighten($main, 10%);
			box-shadow: 1px 1px 5px rgba(black, 0.3), 0 0 2px rgba(black, 0.5); }
		.plus {
			position: absolute;
			width: 2px;
			height: 12px;
			background: #fff;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			&:after {
				width: 100%;
				height: 100%;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				transform: rotate(90deg);
				background: inherit; } } } }

#contentBackend {
	max-width: 1600px;
	width: 100%;

	.content {
		width: 100%;
		position: relative;
		h1 {
			position: relative; }
		#data {
			width: 100%;
			position: relative;
			.noresult {
				position: absolute;
				left: 50%;
				top: 50px;
				transform: translate(-50%);
				z-index: 0;
				opacity: 0.3; }

			.client-group {
				padding: 20px;
				background: #fff;
				border-radius: 4px;
				border-bottom: 1px solid #ddd;
				padding-bottom: 5px;
				position: relative;
				margin-bottom: 1rem;
				h2 {
					grid-column: 1/-1;
					text-transform: uppercase;
					letter-spacing: 0.05rem;
					margin-bottom: 20px;
					display: inline;
					font-size: 1rem;
					color: $main; }
				.project-group {
					border-top: 1px solid #cdcdcd;
					margin: 0 0 20px;
					padding-top: 20px;
					display: grid;
					// display: none
					grid-template-columns: repeat(4, minmax(100px, 400px));
					grid-gap: 10px;
					&:first-of-type {
						border-top: none; }
					textarea {
						background: transparent;
						width: 100%;
						height: 100%;
						font-size: 12px;
						border: 1px solid #1ea6c7;
						color: #1ea6c7;
						padding: 10px;
						transition: all 200ms ease;
						&:read-only {
							color: black;
							padding: 0px;
							border: 1px solid transparent; } }

					.custom--checkbox {
						display: inline-block;
						width: 14px;
						height: 14px;
						position: relative;
						top: 3px;
						border: 1px solid grey;
						border-radius: 2px;
						cursor: pointer; }
					input[type="checkbox"] {
						display: none;
						&:checked {
							~.custom--checkbox {
								border-color: green;
								background: url('../img/checked.svg') center no-repeat;
								background-size: 10px; } } }




					input:not([type="checkbox"]) {
						transition: all 200ms ease;
						margin-bottom: 5px;
						font-size: 12px;
						max-width: 100%;
						width: 200px;
						background: transparent;
						display: inline-block;
						border: 1px solid #1ea6c7;
						color: #1ea6c7;
						padding: 5px 10px;
						&:read-only {
							padding: 5px 0px;
							color: black;
							border: 1px solid transparent; } }
					strong {
						font-weight: normal;
						letter-spacing: 0.05rem;
						text-transform: uppercase;
						font-size: 14px;
						display: block;
						margin-bottom: 10px; }
					b, p, ul, li, span {
						font-size: 12px; }
					b {
						display: inline-block;
						width: 35px; }

					li {
						margin-left: 20px; }
					.project {
						grid-column: 1/-1;
						border-bottom: 1px solid #cdcdcd;
						display: flex;
						justify-content: space-between;
						.projectChange {
							&:read-only {
								padding: 5px 0; }
							width: 300px;
							font-size: 14px;

							letter-spacing: .05rem;
							text-transform: uppercase; }
						strong {
							margin-bottom: 0; } }

					.comment {
						padding: 10px;
						background: #efefef;
						br {
							line-height: 0; } }
					.github {
						color: inherit;
						position: relative;
						font-size: 14px;
						padding-left: 30px;
						border-left: 1px solid #000;
						input {
							&:read-only {
								padding: 5px 0;
								cursor: pointer; }
							font-size: 14px; }

						&:after {
							content: "";
							position: absolute;
							left: 10px;
							top: 1px;
							width: 15px;
							height: 15px;
							background: url(../img/github.svg) center no-repeat;
							background-size: contain; } }
					@media (max-width: 1100px) {
						grid-template-columns: repeat(2, minmax(100px, 400px));
						.project {
							grid-column: 1/-1;
							border-bottom: 1px solid #cdcdcd;
							display: flex;
							justify-content: space-between;
							.editDB {
								margin: 0; } } } } } } } }

.close {
	position: absolute;
	width: 15px;
	margin-left: 10px;
	height: 15px;
	display: inline-block;
	right: 20px;
	top: 20px;
	cursor: pointer;
	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 2px;
		background: $main;
		left: 0;
		top: 0;
		transform: rotate(45deg); }
	&:after {
		content: "";
		position: absolute;
		width: 15px;
		height: 2px;
		background: $main;
		right: 0;
		top: 0;
		transform: rotate(-45deg); } }

.logoutButton {
	box-sizing: border-box;
	background: $main;
	font-size: 12px;
	display: block;
	color: white;
	border-radius: 4px;
	cursor: pointer;
	position: absolute;
	right: 0px;
	top: 0px;
	overflow: hidden;
	box-shadow: 0 0 5px rgba(black, 0.1);
	border: 1px solid $main;
	width: 30px;
	padding: 5px 0;
	transition: all 300ms ease;
	z-index: 99999999;
	&:before {
		content: "";
		height: 100%;
		width: 28px;
		position: absolute;
		background: white url(../img/logout.svg) center center no-repeat;
		background-size: 15px;
		left: 0;
		top: 0; }
	&:hover {
		padding: 5px 10px 5px 35px;
		width: auto; } }
.contentHeader {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px; }
#search, #backendsearch, #adminsearch {
	all: unset;
	position: relative;
	height: 20px;
	top: 5px;
	right: 0px;
	font-size: 12px;
	display: inline-block;
	background: #fff;
	padding: 5px 10px;
	border: 1px solid #efefef; }

#loginscreen {
	position: fixed;
	background: rgba(#202224, 0.98);
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	top: 0;
	left: 0;
	justify-content: center;
	&.main {
		display: flex;
		position: relative; }
	.content {
		padding: 20px;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 0 10px rgba(black, .5);
		border-bottom: 3px solid $main;
		position: relative;
		h3 {
			margin-bottom: 20px; }
		input[type="text"], input[type="password"] {
			all: unset;
			box-sizing: border-box;
			min-width: 300px;
			background: #fcfcfc;
			border: 1px solid #ededed;
			padding: 5px 10px;
			font-size: 12px;
			display: block;
			width: 100%;
			max-width: 100%;
			margin-bottom: 10px;
			&:focus {
				border-bottom: 2px solid $main; } }
		.loginButton {
			all: unset;
			box-sizing: border-box;
			background: $main;
			padding: 5px 10px;
			font-size: 12px;
			display: inline-block;
			color: white;
			border-radius: 4px;
			cursor: pointer;
			float: right; } } }

#errorMsg {
	font-size: 12px;
	color: red;
	max-width: 200px;
	float: left;
	margin-right: 20px; }

.editDB {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.delete, .edit {
		font-size: 12px;
		color: red;
		width: 15px;
		height: 15px;
		display: inline-block;
		margin-right: 10px;
		position: relative;
		cursor: pointer;


		img {

			width: 15px;
			height: 15px; } }
	.edit {
		min-width: 15px;
		&.active {
			width: 100%;
			display: flex;
			align-items: center;
			color: green;
			img {
				margin-left: 5px; } } } }



#editPopup, #deletePopup {
	position: fixed;
	background: rgba(#202224, 0.98);
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	.content {
		background: #fff;
		padding: 20px;
		h3, strong {
			font-size: 12px;
			margin-bottom: 10px; }
		input[type="text"] {
			all: unset;
			box-sizing: border-box;
			min-width: 300px;
			background: #fcfcfc;
			border: 1px solid #ededed;
			padding: 5px 10px;
			font-size: 12px;
			display: block;
			width: 100%;
			max-width: 100%;
			margin-bottom: 10px;
			&:focus {
				border-bottom: 2px solid $main; } }
		textarea {
			all: unset;
			box-sizing: border-box;
			min-width: 300px;
			background: #fcfcfc;
			border: 1px solid #ededed;
			padding: 5px 10px;
			font-size: 12px;
			display: block;
			width: 100%;
			max-width: 100%;
			margin-bottom: 10px;
			height: 50px;
			&:focus {
				border-bottom: 2px solid $main; } }
		label {
			font-size: 12px;
			margin-left: 10px; }
		hr {
			border: none;
			background: #333;
			height: 1px;
			width: 100%;
			margin: 10px 0!important;
			padding: 0; }
		button {
			all: unset;
			box-sizing: border-box;
			background: $main;
			padding: 5px 10px;
			font-size: 12px;
			display: inline-block;
			color: white;
			border-radius: 4px;
			cursor: pointer;
			margin-right: 10px;
			&.confirm {
				background: limegreen; }
			&.cancel {
				background: orangered; } } } }


span.editSelector {
	display: inline-block;
	min-width: 60px;
	min-height: 12px;
	span, p, strong {

		color: black;
		font-size: inherit !important;
		font-family: inherit!important; } }
span.editSelector {
	color: black;
	font-size: 12px !important; }
.editable {
	color: #1ea6c7!important;
	* {
		color: #1ea6c7!important; } }



.projectChange {
	display: inline-block!important;
	margin-right: 10px; }
#pagination {
	margin-top: 2rem;

	span {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: white;
		width: 30px;
		height: 30px;
		background: #333;
		cursor: pointer;
		margin-right: 0.5rem;
		border-radius: 2px;
		transition: all 300ms ease;
		&:hover {
			background: lighten($main, 10%); }
		&.active {
			background: $main; } } }
.showing {
	display: block!important; }
.hidden {
	display: none!important; }



.entry--group {
	margin-top: 1rem;
	padding-top: 1rem;
	margin-bottom: 1rem;
	border-top: 1px solid #efefef;

	h3 {
		font-weight: 400;
		letter-spacing: .05rem;
		text-transform: uppercase;
		font-size: 14px;
		display: block;
		margin-bottom: 10px; }
	.input--content {
		.input--group {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			label {
				min-width: 60px;
				display: inline-block;
				font-size: 12px;
				font-weight: bold;
				margin-right: 1rem; }
			input {
				border: none;
				font-size: 12px;
				flex: 1 0 0; } } } }
#loader {
	display: none; }
.loading {
	#loader {
		display: block; } }

.main--button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 300;
	// letter-spacing: 0.05rem
	text-transform: uppercase;
	padding: 0.5rem 1rem;
	background: $main;
	background: white;
	border-radius: 2px;
	position: relative;
	cursor: pointer;
	margin-bottom: 1rem;
	color: black;
	.plus {
		position: relative;
		width: 2px;
		height: 10px;
		background: $main;
		display: inline-block;
		margin-left: 1rem;
		&:after {
			width: 100%;
			height: 100%;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			transform: rotate(90deg);
			background: inherit; } } }

#contentBackend {
	.content {
		#data.data--container {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			grid-gap: 1rem;
			.client-group {
				overflow: hidden;
				padding: 0!important;
				margin: 0!important;
				h2 {
					margin: 0!important;
					padding: 0.5rem 1rem!important;
					background: $main;
					color: white!important;
					display: flex!important;
					justify-content: space-between;
					width: 100%; }
				h3 {
					display: flex;
					justify-content: space-between;
					align-items: center;
					span {
						img {
							margin-left: 0.5rem;
							cursor: pointer; } } }
				.entry--group {
					margin-top: 0;
					padding: 1rem;
					.input--group {
						margin-bottom: 0.5rem;
						input {
							transition: all 300ms ease; } }

					&.editing {
						input {
							border: 1px solid #1ea6c7;
							padding: 0.5rem 1rem; } } } } } } }

.modal--window {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(black, 0.9);
	.modal--content {
		border-radius: 4px;
		background: white;
		padding: 2rem;
		text-align: center;
		button {
			all: unset;
			box-sizing: border-box;
			background: $main;
			padding: 5px 20px;
			font-size: 14px;
			display: inline-block;
			color: white;
			border-radius: 4px;
			cursor: pointer;
			margin-right: 10px;
			margin-top: 1rem;
			&.confirm {
				background: limegreen; }
			&.cancel {
				background: orangered; } } } }

.content--group {
	padding-top: 10px;
	border-top: 1px solid #efefef;
	&.empty {
 }		// display: none
	strong {
		font-size: 12px;
		display: block;
		margin-bottom: 10px; }
	input[type="checkbox"] {
		max-width: 14px; }
	label {
		color: #333;
		font-size: 10px!important; } }
.input--content {
	textarea {
		width: 100%;
		display: block;
		padding: 5px;
		font-size: 12px;
		border: 1px solid #cdcdcd;
		background: #efefef;
		margin-top: 20px;
		* {
			font-size: 12px; } } }
.editing {
	.content--group {
		&.empty {
			display: block; } } }

#data.backend--container {
	grid-template-columns: 1fr!important;
	.client-group {
		background: #efefef!important;
		h2 {
			cursor: pointer;
			background: #797f8c!important; }
		.entry--group {
			background: white;
			margin: 10px!important;
			border-radius: 5px;
			display: none;
			.input--content {
				@media (min-width: 1200px) {
					display: grid;
					grid-gap: 10px;
					grid-template-columns: repeat(3, minmax(250px, 1fr)); }
				.input--group {
					&:first-child {
						grid-column: 1 / -1;
						margin-bottom: 10px; } } } }
		&.active {
			h2 {
				background: $main!important; }
			.entry--group {
				display: block; } } } }

#index {
    display: grid;
    grid-template-columns: minmax(200px, 220px) minmax(300px, 1fr);
    background: #f5f5f5;
    .toggle--nav {
        display: none; }
    .logoutButton {
        position: fixed;
        top: 2rem;
        right: 2rem;
        z-index: 99;
        color: white; }
    .col-1 {
        background: #202224;
        position: relative;
        width: 220px;
        height: 100vh;
        border-right: 1px solid #ededed;
        padding-bottom: 30px;
        nav {
            position: fixed;
            width: 220px;
            overflow: auto;
            max-height: 100%;
            background: #202224; }
        .logoContainer {
            position: relative;
            background: #fff;


            #logo {
                width: 74px;
                position: relative;
                margin: 30px; } }

        .menuWrapper {
            margin-top: 40px; } }


    .col-2 {
       margin: 20px;
       max-width: 100%;
       padding: 40px 0px;
       display: flex;
       justify-content: center;
       align-items: center;
       position: relative; }

    @media (max-width: 600px) {
        display: block;
        .col-1 {
            transition: all 300ms ease;
            transform: translateX(-100%);
            .toggle--nav {
                display: block;
                position: absolute;
                top: 10px;
                right: -30px;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #202224;
                width: 30px;
                height: 30px;
                cursor: pointer;
                transition: all 300ms ease;
                &:after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 0;
                    height: 0;
                    border: 5px solid transparent;
                    border-left-color: white;
                    transform: translateX(3px); } }
            &.active {
                transform: translateX(0);
                .toggle--nav {
                    right: 0;
                    &:after {
                        border: 5px solid transparent;
                        border-right-color: white;
                        transform: translateX(-3px); } } } }


        .col-1 {
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 10; } }



    #textstartseite {
        // max-width: 800px
        align-items: center;
        margin: 0 auto; }

    table {
        width: 100%;
        tbody {
            width: 100%;
            display: block;
            tr {
                display: flex;
                flex-wrap: wrap; }
            th {
                width: 25%;
                min-width: 200px;
                background: #7D7D7D;
                color: white;
                padding: 10px 20px;
                font-weight: 200;
                text-align: left; }
            .firma {
                background: #bcbcbc!important; }
            td {
                padding: 5px 20px;
                min-width: 200px;
                width: 25%;
                text-align: left; }
            tr {
                &:nth-child(even) {
                    background: #f4f4f4; } } } } }

#content {
    text-align: left;
    display: flex;
    width: 100%;
    max-width: 1200px;

    div.container {
        max-width: 100%;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(black, 0.1);
        padding: 40px;
        // min-height: calc(100vh - 120px)
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1rem;
        // align-items: center
        h1, h2, p, table, div, img, ul, a, h3, b, hr, xmp {
            //margin: 0
            display: block;
 }            // width: 100%
        hr {
            margin: 2rem 0; }
        p, b {
            font-size: 1rem; }
        h1 {
            color: $main;
            margin-bottom: 20px; }

        h2 {
            color: $main;
            margin-bottom: 20px!important;
            text-transform: uppercase; }
        h3 {
            font-size: 1.3rem;
            margin-bottom: 20px!important;
            text-transform: uppercase; }
        ul {
            li {
                font-size: 1rem;
                margin-left: 20px;
                margin-bottom: 20px; } }
        .terminal {
            display: block;
            padding: 10px;
            margin: 10px 0;
            color: white;
            border-radius: 2px;
            background: #000;
            font-size: 1rem;
            overflow-x: scroll;
            white-space: nowrap;
            font-family: Menlo,Monaco,"Courier New",Courier,monospace; }
        a {
            display: inline;
            font-size: 1rem; }
        xmp {
            font-family: Menlo,Monaco,"Courier New",Courier,monospace; }
        b {
            margin-bottom: 20px;
            color: $main; } } }



